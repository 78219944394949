import React from "react";
import externalUrlIcon from "../../assets/images/icon-external-link.svg";

const ICPInformation = () => {
  return (
    <a className="tc-labeltext" href="https://beian.miit.gov.cn/" target="_blank">
      Shanghai, ICP prepared No. 14027180
      <img className="pl-1" src={externalUrlIcon} />
    </a>
  );
};
export default ICPInformation;
