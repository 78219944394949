import React, { useEffect, useState } from 'react';
import './TransitView.scss';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { addError } from '../../utils/analytics';
import { getAwbShipmemtDetails } from '../../services/transportationPlanServices';
import LoadingIndicator from '../LoadingIndicator';
import MESSAGES from '../../constants/Messages';
import { useTranslation } from 'react-i18next';
import { getVolumeUnit, getWeightUnit } from '../../constants';
import { getShipmentViewDetailsFormatted } from './TransitViewUtils';


const TransitView = () => {

  const { t } = useTranslation(["labels", "errors"]);
  const history = useHistory()

  const location = useLocation();
  const awbNumber = location.state?.awbNumber;

  const [awbViewData, setAwbViewData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const backButtonClickHandler = ()=> {
    history.push('/shipment-tracking/list')
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      try {
        await getAwbShipmemtDetails(awbNumber).then(res => {
          if (res?.data) {
            setAwbViewData(res?.data)
          }
        })
        setIsLoading(false)
      } catch (error) {
        console.log(error.message);
        addError("LOADING_AWB_SHIPMENT_FAILED", error);
      }
      setIsLoading(false)
    })();
  }, [])


  return (
    <div className="shipmentTransitviewContainer col-lg-12 mt-5">
      {isLoading ? <LoadingIndicator /> : <></>}
  
      {awbViewData ?
        <div className="card">
          <h3 className="page-sub-head ml-0">Track your shipment</h3>
          <div className='shipment-transit-summary'>
            {/* Shipment Information */}
            <div className="row mt-4">
              <div className="col-md-2 mr-2">
                <h5>AWB</h5>
                <p>{awbViewData?.awbNumber}</p>
              </div>
              <div className="col-md-2 mr-2">
                <h5>Shipment Date</h5>
                <p>{awbViewData?.shipmentDate}</p>
              </div>
              <div className="col-md-2 mr-3">
                <h5>Status</h5>
                <p>{awbViewData?.shipmentStatus}</p>
              </div>
              <div className="col-md-4 mr-2">
                <h5>Estimated time of delivery</h5>
                <p>{awbViewData?.dlvPlannedDate}</p>
                {/* {awbViewData.estimatedDelivery.updated ? (
                  <div>
                    <p>
                      <div className='estimated-time'>
                        <span className="text-strike">
                          {awbViewData.estimatedDelivery.original}
                        </span>
                        <span className="badge bg-delayed text-dark ms-2">
                          Potential delay <span className='delay-hours'> ({awbViewData.estimatedDelivery.delay})</span>
                        </span>
                      </div>
                      <span className="text-danger">
                        {awbViewData.estimatedDelivery.updated}
                      </span>

                    </p>
                  </div>
                ) : (
                  <p>{awbViewData.estimatedDelivery.original}</p>
                )} */}
              </div>
            </div>

            {/* Second Row */}
            <div className="row mt-3">
              <div className="col-md-2 mr-2">
                <h5>Origin - Destination</h5>
                <p>{awbViewData?.origin} - {awbViewData?.destination}</p>
              </div>
              <div className="col-md-2 mr-2">
                <h5>Shipment info</h5>
                <p className='shipment-info'>{`${awbViewData?.commodity}-\n${awbViewData?.product}-${awbViewData?.sccCode}`}</p>
              </div>
              <div className="col-md-1 mr-2">
                <h5>Pieces</h5>
                <p>{awbViewData?.pieces}</p>
              </div>
              <div className="col-md-1 mr-2">
                <h5>Weight</h5>
                <p>{`${awbViewData?.weight} ${getWeightUnit(awbViewData?.weightUnit)}`}</p>
              </div>
              <div className="col-md-2 mr-2">
                <h5>Volume</h5>
                <p>{`${awbViewData?.volume} ${getVolumeUnit(awbViewData?.volumeUnit)}`}</p>
              </div>
            </div>
          </div>

          {/* Timeline Section */}
          <div className="shipment-card-container mt-5">
            <div className="timeline">
              {getShipmentViewDetailsFormatted(awbViewData)?.map((eachCardData, index) => (
                <div
                  key={index}
                  className={`timeline-item ${eachCardData?.colorSchemes?.lineStatus}`}
                >
                  <div
                    className={`timeline-dot ${eachCardData?.colorSchemes?.circleStatus}`}
                  ></div>
                  <h5 className="timeline-heading">{eachCardData?.location}</h5>
                  <div className="timeline-content">
                    {eachCardData?.cards.map((card, cardIndex) => (
                      <div key={cardIndex} className="card mb-2">
                        <p className='description-section'>{card.description}</p>
                        <div>
                          {/* {card.updatedDateTime ? (
                            <div>
                              <span className="card-text-strike">
                                {card.dateTime}
                              </span>
                              <p className="text-danger">{card.updatedDateTime}</p>
                            </div>
                          ) : (
                            <p>{card.dateTime}</p>
                          )} */}
                          <p className='datetime-section'>{card.dateTime}</p>
                        </div>
                        <span
                          className={`badge ${card?.statusBg}`}
                        >
                          {card.status}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Back button */}
          <div className="mt-4">
            <button className="secondary-button" onClick={backButtonClickHandler}>Back</button>
          </div>
        </div>
        : <h3 className="page-sub-head">{t(MESSAGES.LOADING, { ns: 'errors' })}</h3>}
    </div>
  );
};

export default TransitView;
