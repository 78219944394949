import moment from "moment";
import { Calendar } from "primereact/calendar";
import React, { useEffect, useState } from "react";
import { Alert } from "../../common/Alert";
import { Dropdown } from "../../common/DropDown";
import {
  AUTOCOMPLETE_TYPE,
  NOTIFICATION_MESSAGE_TYPE,
  SHIPMENT_STATUS,
} from "../../constants";
import { PRODUCT_PLACEHOLDER } from "../../constants/Placeholders";
import filterIcon from "../../assets/images/filter.svg";
import AirportAutocomplete from "../AirportAutocomplete";
import ProductAutocomplete from "../ShippingItemAutocomplete/ProductAutoComplete";
import "./TrackShipmentListView.scss";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../LoadingIndicator";
import { getProducts } from "../../services/manageBookingService";
import { getAirportsByCode } from "../../services/searchPanelServices";
import getCurrentUser from "../../utils/getCurrentUser";
import { addError } from "../../utils/analytics";
import {
  allowNumricOnly,
  formatInputTypeNumber,
  getFormattedDataForTable,
  getShipmentsListRequest,
  removeError,
  validateAWB,
  validateAwbPrefixRequired,
} from "./ShipmentTrackingUtils";
import { getShipmentsList } from "../../services/transportationPlanServices";
import PaginatorComponent from "../Pagination/PaginatorComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  getFromLocalStorage,
  setToLocalStorage,
} from "../../utils/StorageUtils";
import sessionKeys from "../../constants/sessionKeys";
import Messages from "../../constants/Messages";

const TrackShipmentListView = () => {
  const searchPanelInitialData = {
    origin: null,
    destination: null,
    fromBookingDate: moment(
      new Date().setDate(new Date().getDate() - 14)
    ).toDate(),
    toBookingDate: new Date(),
    shipmentIdentifierDetails: {
      shipmentPrefix: "607",
      masterDocumentNumber: "",
    },
    advancedSearch: {
      product: null,
      bookingStatus: null,
    },
    agentCode: null,
  };

  const { t, i18n } = useTranslation(["labels", "errors"]);
  const history = useHistory();

  const [bookingSearchFormData, setBookingSearchFormData] = useState(
    searchPanelInitialData
  );
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [awbList, setAwbList] = useState([]);
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [paginationData, setPaginationData] = useState({
    currentPageNumber: 0,
    recordsPerPage: 0,
    totalPages: 0,
    combinedPages: 0,
  });

  const [notificationType, setNotificationType] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [sortField, setSortField] = useState({ sortBy: "", sortOrder: "" });

  const clearError = () => {};

  const showErrorAlert = () => {
    setNotificationMessage(
      t(Messages.PROCESS_REQUEST_FAILED, { ns: "errors" })
    );
    setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR);
  };

  const setShipmentListAndPaginationData = (response) => {
    const { transportationPlans, totalPages, pageNumber, pageSize } =
      response.data;
    if (transportationPlans) {
      setAwbList(transportationPlans);
      setPaginationData({
        currentPageNumber: pageNumber ? pageNumber + 1 : 0,
        combinedPages: totalPages * pageSize,
        recordsPerPage: pageSize,
        totalPages,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isAwbValid = validateAWB(
      bookingSearchFormData?.shipmentIdentifierDetails?.masterDocumentNumber,
      setError,
      "awbNumber"
    );
    const isAwbPrefixValid = validateAwbPrefixRequired(
      bookingSearchFormData?.shipmentIdentifierDetails?.masterDocumentNumber,
      bookingSearchFormData?.shipmentIdentifierDetails?.shipmentPrefix,
      setError,
      "awbPrefix"
    );
    const isAwbFieldEmpty =
      bookingSearchFormData?.shipmentIdentifierDetails?.masterDocumentNumber?.trim()
        ?.length === 0;

    if (isAwbFieldEmpty) {
      if (!bookingSearchFormData?.fromBookingDate) {
        setError((prevState) => ({
          ...prevState,
          fromBookingDate: Messages.FIELD_REQUIRED,
        }));
      }

      if (!bookingSearchFormData?.toBookingDate) {
        setError((prevState) => ({
          ...prevState,
          toBookingDate: Messages.FIELD_REQUIRED,
        }));
      }
    }

    if (
      isAwbValid &&
      isAwbPrefixValid &&
      (isAwbFieldEmpty
        ? bookingSearchFormData?.fromBookingDate &&
          bookingSearchFormData?.toBookingDate
        : true)
    ) {
      try {
        setIsLoading(true);
        const response = await getShipmentsList(
          getShipmentsListRequest(bookingSearchFormData, selectedFilter)
        );
        if (response?.data) {
          setIsLoading(false);
          setShipmentListAndPaginationData(response);
          setToLocalStorage(
            sessionKeys.SHIPMENT_TRACKING_DETAILS,
            JSON.stringify({
              bookingSearchFormData,
              selectedFilter,
              paginationData,
              sortField,
            })
          );
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
        addError("SHIPMENTS_LIST_ERROR", error);
        showErrorAlert();
      }
      setIsLoading(false);
    }
  };

  const handleSetError = (key, text) => {
    error
      ? setError({
          ...error,
          [key]: text,
        })
      : setError({
          [key]: text,
        });
  };

  const handleAlertClose = () => {
    setNotificationMessage("");
    setNotificationType(null);
  };

  const draftCallback = () => {};

  // Filtered shipments based on selected filter
  const filteredShipments = [].filter((shipment) => {
    if (selectedFilter === "All") return true;
    if (selectedFilter === "Booked") {
      return shipment.status === "Confirmed" || shipment.status === "Queued";
    }
    if (selectedFilter === "Live") {
      return (
        shipment.status === "Received" ||
        shipment.status === "Notified for Delivery" ||
        shipment.status === "Departed" ||
        shipment.status === "Arrived"
      );
    }
    if (selectedFilter === "Completed") {
      return shipment.status === "Completed";
    }
    return false;
  });

  // Handler to change filter
  const handleFilterChange = async (filter) => {
    try {
      setIsLoading(true);
      const response = await getShipmentsList(
        getShipmentsListRequest(bookingSearchFormData, filter)
      );
      if (response?.data) {
        setIsLoading(false);
        setShipmentListAndPaginationData(response);
        setSelectedFilter(filter);
        setSortField({ sortBy: "", sortOrder: "" });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      addError("SHIPMENTS_LIST_ERROR", error);
      showErrorAlert();
    }
    setIsLoading(false);
  };

  const [showFilterBox, setShowFilterBox] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  const handleFilterIconClick = () => {
    setShowFilterBox((prev) => !prev);
  };

  const handleCheckboxChange = (status) => {
    const updatedStatuses = selectedStatuses.includes(status)
      ? selectedStatuses.filter((item) => item !== status)
      : [...selectedStatuses, status];

    setSelectedStatuses(updatedStatuses);

    // Apply the filter immediately
    // handleFilterChange(updatedStatuses);
  };

  const isAllSelected =
    selectedStatuses.length === 0 || selectedStatuses.length === 4;

  const handleChangeOfAWB = (value) => {
    if (value.length <= 12) {
      setBookingSearchFormData((prev) => ({
        ...prev,
        shipmentIdentifierDetails: {
          ...prev?.shipmentIdentifierDetails,
          masterDocumentNumber: value,
        },
      }));
    }
  };

  const advancedSearchAction = (isOpen) => {
    setBookingSearchFormData((prev) => ({
      ...prev,
      advancedSearch: {
        product: isOpen
          ? {
              name: "All",
            }
          : null,
        bookingStatus: isOpen ? "All" : null,
      },
    }));
    setAdvancedSearch(isOpen);
  };

  const onPageIndexChange = async (e) => {
    try {
      setIsLoading(true);
      const response = await getShipmentsList(
        getShipmentsListRequest(bookingSearchFormData, selectedFilter, e, sortField)
      );
      if (response?.data) {
        setIsLoading(false);
        setShipmentListAndPaginationData(response);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      addError("SHIPMENTS_LIST_ERROR", error);
      showErrorAlert();
    }
    setIsLoading(false);
  };

  const viewClickHandler = (shipmentData) => {
    history.push("/shipment-tracking/view", {
      awbNumber: shipmentData?.awbNumber,
    });
  };

  useEffect(() => {
    (async () => {
      const bookingData = getFromLocalStorage(
        sessionKeys.SHIPMENT_TRACKING_DETAILS
      );
      let selectedFilter = "All";
      let initialBookingData = searchPanelInitialData;
      let defaultOriginCode = null;
      let paginationDataInitial = {
        currentPageNumber: 0,
        recordsPerPage: 0,
        totalPages: 0,
        combinedPages: 0,
      };
      let sortFieldInitalData = { sortBy: "", sortOrder: "" };
      if (bookingData) {
        const storedData = JSON.parse(bookingData);
        const parsedData = storedData?.bookingSearchFormData;
        selectedFilter = storedData?.selectedFilter;
        paginationDataInitial = storedData?.paginationData;
        sortFieldInitalData = storedData?.sortField;
        if (
          parsedData?.advancedSearch?.product ||
          parsedData?.advancedSearch?.bookingStatus
        ) {
          setAdvancedSearch(true);
        }
        initialBookingData = parsedData;
        setBookingSearchFormData(parsedData);
      } else if (!bookingSearchFormData.origin) {
        const gsaAgentDetails = JSON.parse(
          getFromLocalStorage(sessionKeys.GSA_AGENT_DETAILS)
        );
        const userDetails = getCurrentUser();
        let defaultAirport =
          userDetails && userDetails.defaultAirportCode
            ? userDetails.defaultAirportCode
            : userDetails?.stationCode;
        if (gsaAgentDetails && gsaAgentDetails?.agentStationCode) {
          defaultAirport = gsaAgentDetails.agentStationCode;
        }
        if (userDetails && defaultAirport) {
          await getAirportsByCode(defaultAirport)
            .then((defaultOrigin) => {
              if (defaultOrigin) {
                defaultOriginCode = defaultOrigin;
                setBookingSearchFormData((prev) => ({
                  ...prev,
                  origin: defaultOrigin,
                }));
              }
            })
            .catch((error) => {
              console.log(error);
              addError("LOADING_ERROR", error);
            });
        }
      }
      try {
        setIsLoading(true);
        await getShipmentsList(
          getShipmentsListRequest(
            bookingData
              ? initialBookingData
              : { ...initialBookingData, origin: defaultOriginCode },
            selectedFilter,
            paginationDataInitial.currentPageNumber,
            sortFieldInitalData
          )
        ).then((response) => {
          if (response?.data) {
            setShipmentListAndPaginationData(response);
            setIsLoading(false);
            setSelectedFilter(selectedFilter);
            setSortField(sortFieldInitalData);
          }
        });
      } catch (error) {
        console.log(error.message);
        addError("SHIPMENTS_LIST_ERROR", error);
        showErrorAlert();
      }
      setIsLoading(false);
    })();

    (async () => {
      try {
        const productList = await getProducts();
        const allOption = {
          name: "All",
        };
        productList.unshift(allOption);
        if (productList) {
          setProducts(productList);
        }
      } catch (error) {
        console.log(error.message);
        addError("LOADING_PRODUCT_ERROR", error);
      }
    })();

    window.scrollTo(0, 0);
  }, []);

  const awbRowClickHandler = (shipment) => {
    setToLocalStorage(
      sessionKeys.SHIPMENT_TRACKING_DETAILS,
      JSON.stringify({
        bookingSearchFormData,
        selectedFilter,
        paginationData,
        sortField,
      })
    );
    const { awbNumber, ubrNumber } = shipment;
    const urlString = `/manage-booking/${awbNumber?.replaceAll("-", "")}${
      ubrNumber ? `/${ubrNumber}` : ""
    }`;
    history.push(urlString.trim(), { callerScreen: "ShipmentTracking" });
  };

  const handleSort = async (field) => {
    const { sortOrder, sortBy } = sortField;
    const isSameFieldClicked = field === sortBy;
    let newSortOrder = isSameFieldClicked ? sortOrder : "";
    switch (newSortOrder) {
      case "asc":
        newSortOrder = "desc";
        break;
      case "desc":
        newSortOrder = "";
        break;
      case "":
        newSortOrder = "asc";
        break;
      default:
        newSortOrder = "";
    }
    const sortData = {
      sortBy:field,
      sortOrder: newSortOrder,
    };
    try {
      setIsLoading(true);
      const response = await getShipmentsList(
        getShipmentsListRequest(
          bookingSearchFormData,
          selectedFilter,
          paginationData.currentPageNumber,
          sortData
        )
      );
      if (response?.data) {
        setIsLoading(false);
        setSortField(sortData);
        setShipmentListAndPaginationData(response);
        setToLocalStorage(
          sessionKeys.SHIPMENT_TRACKING_DETAILS,
          JSON.stringify({
            bookingSearchFormData,
            selectedFilter,
            paginationData,
            sortField,
          })
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      addError("SHIPMENTS_LIST_ERROR", error);
      showErrorAlert();
    }
    setIsLoading(false);
  };

  const getSortArrowByType = (type) => {
    switch (type) {
      case "asc":
        return "pi-sort-amount-up-alt";
      case "desc":
        return "pi-sort-amount-down";
      default:
        return "pi-sort-alt";
    }
  };

  return (
    <div className="shipment-track-container">
      <h3 className="page-sub-head ">Track your shipment</h3>
      {notificationMessage && notificationMessage !== "" && (
        <div className="pb-2">
          <Alert
            showAlert={true}
            message={t(notificationMessage, { ns: "errors" })}
            setTimeout={true}
            onAlertClose={handleAlertClose}
            type={notificationType}
          />
        </div>
      )}
      {isLoading ? <LoadingIndicator /> : <></>}
      <div className="shipment-track-search" id="list-booking">
        <div id="basicSearch">
          <div className="form-row mb-3">
            <div
              className="col-12 col-lg-3 form-item form-item--text"
              data-module="molecules/form-item/FormItem"
            >
              <label className="form-item__label">{t("COMMON.SHP_FROM")}</label>
              <AirportAutocomplete
                autoCompleteType={AUTOCOMPLETE_TYPE.ORIGIN}
                selectedOrigin={bookingSearchFormData?.origin}
                selectedDestination={bookingSearchFormData?.destination}
                setSelectedOrigin={(e) =>
                  setBookingSearchFormData((prev) => ({ ...prev, origin: e }))
                }
                handleSetError={handleSetError}
                clearError={clearError}
                screen="MANAGE_BOOKING"
                draftCallback={draftCallback}
                setNotificationMessage={setNotificationMessage}
                setNotificationType={setNotificationType}
              />
            </div>
            <div
              className="col-12 col-lg-3 form-item form-item--text"
              data-module="molecules/form-item/FormItem"
            >
              <label className="form-item__label">{t("COMMON.SHP_TO")}</label>
              <AirportAutocomplete
                autoCompleteType={AUTOCOMPLETE_TYPE.DESTINATION}
                selectedOrigin={bookingSearchFormData?.origin}
                selectedDestination={bookingSearchFormData?.destination}
                setSelectedDestination={(e) =>
                  setBookingSearchFormData((prev) => ({
                    ...prev,
                    destination: e,
                  }))
                }
                handleSetError={handleSetError}
                clearError={clearError}
                screen="MANAGE_BOOKING"
                draftCallback={draftCallback}
                setNotificationMessage={setNotificationMessage}
                setNotificationType={setNotificationType}
              />
            </div>
            <div
              className={`col-12  col-lg-3 calendar-group form-item ${
                error && error["fromBookingDate"] ? "red-border" : ""
              }`}
            >
              <label className="form-item__label">
                {t("SHIPMENT_LIST.SHP_CREATION_FRM")}
              </label>

              <Calendar
                className="form-item__field"
                dateFormat="dd M yy"
                value={moment(bookingSearchFormData.fromBookingDate).toDate()}
                // maxDate={moment().toDate()}
                onChange={(e) => {
                  setBookingSearchFormData({
                    ...bookingSearchFormData,
                    fromBookingDate: e.target?.value,
                  });
                }}
                onFocus={(e) => {
                  setError((prevState) => ({
                    ...prevState,
                    ["fromBookingDate"]: "",
                  }));
                }}
                placeholder="Booking date (from)"
                showOtherMonths={false}
              />
              <span className="form-item__error">
                {error && error["fromBookingDate"]
                  ? t(error["fromBookingDate"], { ns: "errors" })
                  : ""}
              </span>
            </div>

            <div
              className={`col-12  col-lg-3 calendar-group form-item ${
                error && error["toBookingDate"] ? "red-border" : ""
              }`}
            >
              <label className="form-item__label">
                {t("SHIPMENT_LIST.SHP_CREATION_TO")}
              </label>

              <Calendar
                className="form-item__field"
                dateFormat="dd M yy"
                value={moment(bookingSearchFormData.toBookingDate).toDate()}
                minDate={moment(bookingSearchFormData.fromBookingDate).toDate()}
                // maxDate={moment().toDate()}
                onChange={(e) => {
                  setBookingSearchFormData({
                    ...bookingSearchFormData,
                    toBookingDate: e.target?.value,
                  });
                }}
                onFocus={(e) => {
                  setError((prevState) => ({
                    ...prevState,
                    ["toBookingDate"]: "",
                  }));
                }}
                placeholder="Booking date (to)"
                showOtherMonths={false}
              />
              <span className="form-item__error">
                {error && error["toBookingDate"]
                  ? t(error["toBookingDate"], { ns: "errors" })
                  : ""}
              </span>
            </div>
            <div
              className="col-12 mt-lg-2 col-lg-3 form-item form-item--text"
              data-module="molecules/form-item/FormItem"
            >
              <label className="form-item__label">
                {t("COMMON.AWB_NUMBER")}
              </label>
              <div className="form-row">
                <div className="col-4 col-md-4">
                  <input
                    id="awbPrefix"
                    name="awbPrefix"
                    type="text"
                    className="form-item__field"
                    value={
                      bookingSearchFormData?.shipmentIdentifierDetails
                        ?.shipmentPrefix
                    }
                    maxLength="3"
                    onKeyPress={(evt) => {
                      return allowNumricOnly(evt);
                    }}
                    onChange={(e) => {
                      setBookingSearchFormData((prev) => ({
                        ...prev,
                        shipmentIdentifierDetails: {
                          ...prev?.shipmentIdentifierDetails,
                          shipmentPrefix: e.target?.value,
                        },
                      }));
                    }}
                    onFocus={(e) => {
                      removeError(e, setError);
                    }}
                  />
                  <span className="form-item__error">
                    {error && error["awbPrefix"]
                      ? t(error["awbPrefix"], { ns: "errors" })
                      : ""}
                  </span>
                </div>

                <div className="col-8 col-md-8">
                  <input
                    id="awbNumber"
                    name="awbNumber"
                    type="number"
                    className="form-item__field"
                    placeholder="AWB"
                    value={
                      bookingSearchFormData?.shipmentIdentifierDetails
                        ?.masterDocumentNumber
                    }
                    onKeyDown={(evt) => formatInputTypeNumber(evt)}
                    onChange={(e) => handleChangeOfAWB(e.target?.value)}
                    onBlur={(e) => {
                      validateAWB(e.target?.value, setError, "awbNumber");
                    }}
                    onFocus={(e) => {
                      removeError(e, setError);
                    }}
                  />
                  <span className="form-item__error">
                    {error && error["awbNumber"]
                      ? t(error["awbNumber"], { ns: "errors" })
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-row mt-lg-5 mt-lg-3">
          <div className="col-12">
            {!advancedSearch ? (
              <div className="form-row">
                <div
                  className="advanced-serach col-12 col-lg-6 mt-3 mb-4 m-lg-0"
                  onClick={() => advancedSearchAction(true)}
                >
                  <img
                    className="plus-icon"
                    src={require("../../assets/images/plus.svg").default}
                  />
                  <a className="link">
                    <span>{`${t("COMMON.ADV_SEARCH")}`}</span>
                  </a>
                </div>
                <div className="col-12 col-lg-6 cta_wrap flex-column flex-lg-row text-right p-1">
                  <button
                    type="button"
                    className="primary-button"
                    onClick={handleSubmit}
                  >
                    {t("COMMON.SEARCH")}
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="mt-3 m-lg-0 m-lg-0"
                onClick={() => {
                  advancedSearchAction(false);
                }}
              >
                <img
                  className="plus-icon"
                  src={require("../../assets/images/minus.svg").default}
                />
                <a className="link">
                  <span>{`${t("COMMON.ADV_SEARCH")}`}</span>
                </a>
              </div>
            )}
          </div>
        </div>

        {advancedSearch ? (
          <div id="advancedSearch">
            <div className="form-row custom-margin-search mb-3">
              <div
                className="col-12 col-lg-3 form-item form-item--text autocomplete-dropdown"
                data-module="molecules/form-item/FormItem"
              >
                <label className="form-item__label">{t("COMMON.PRD")}</label>

                <ProductAutocomplete
                  selectedProduct={
                    bookingSearchFormData?.advancedSearch?.product
                  }
                  setSelectedProduct={(e) => {
                    setBookingSearchFormData((prev) => ({
                      ...prev,
                      advancedSearch: { ...prev.advancedSearch, product: e },
                    }));
                  }}
                  products={products}
                  placeholder={t(PRODUCT_PLACEHOLDER)}
                />
              </div>
              <div className="col-12 col-lg-3 form-item mb-0">
                <Dropdown
                  className="cust-dropdown"
                  value={bookingSearchFormData?.advancedSearch?.bookingStatus}
                  options={SHIPMENT_STATUS}
                  label={t("SHIPMENT_LIST.SHP_STATUS")}
                  onChange={(e) => {
                    const { value } = e.target;
                    setBookingSearchFormData((prev) => ({
                      ...prev,
                      advancedSearch: {
                        ...prev.advancedSearch,
                        bookingStatus: value,
                      },
                    }));
                  }}
                ></Dropdown>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {advancedSearch && (
          <div className="form-row mt-5 mt-md-4">
            <div className="col-12 cta_wrap flex-column flex-lg-row text-right">
              <button
                type="button"
                className="primary-button"
                onClick={handleSubmit}
              >
                {t("COMMON.SEARCH")}
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="shipment-track-table my-5">
        <div className="table-filters">
          <ul className="status-filters">
            <li
              className={selectedFilter === "All" ? "active" : ""}
              onClick={() => handleFilterChange("All")}
            >
              All
            </li>
            <li
              className={selectedFilter === "Booked" ? "active" : ""}
              onClick={() => handleFilterChange("Booked")}
            >
              Booked
              <img src={require("../../assets/images/tick-blue.svg").default} />
            </li>
            <li
              className={selectedFilter === "Live" ? "active" : ""}
              onClick={() => handleFilterChange("Live")}
            >
              Live
              <img
                src={
                  require("../../assets/images/right-arrow-orange.svg").default
                }
              />
            </li>
            <li
              className={selectedFilter === "Completed" ? "active" : ""}
              onClick={() => handleFilterChange("Completed")}
            >
              Completed
              <img
                src={require("../../assets/images/green-thumbs-up.svg").default}
              />
            </li>
          </ul>
        </div>
        {awbList?.length !== 0 ? (
          <>
            <table className="table-responsive-sm shipment-table">
              <thead>
                <tr>
                  <th scope="col" className="col-2">
                    <span>
                      AWB
                      <i
                        className={`sort-icon pi ${
                          sortField?.sortBy === "AwbNumber"
                            ? getSortArrowByType(sortField?.sortOrder)
                            : "pi-sort-alt"
                        }`}
                        onClick={() => handleSort("AwbNumber")}
                      ></i>
                    </span>
                  </th>
                  <th scope="col" className="col-2">
                    Origin - Destination
                  </th>
                  <th scope="col" className="col-2">
                    <span>
                      Shipment Date
                      <i
                        className={`sort-icon pi ${
                          sortField?.sortBy === "ShipmentDate"
                            ? getSortArrowByType(sortField?.sortOrder)
                            : "pi-sort-alt"
                        }`}
                        onClick={() => handleSort("ShipmentDate")}
                      ></i>
                    </span>
                  </th>
                  <th scope="col" className="col-2">
                    <span>
                      Status
                      <i
                        className={`sort-icon pi ${
                          sortField?.sortBy === "Status"
                            ? getSortArrowByType(sortField?.sortOrder)
                            : "pi-sort-alt"
                        }`}
                        onClick={() => handleSort("Status")}
                      ></i>
                    </span>
                  </th>
                  <th scope="col" className="col-2">
                    <span>Shipment Info</span>
                  </th>
                  {/* <th scope="col" className="col-1">
                    Actions
                  </th> */}
                  {/* {(selectedFilter === "Live" || selectedFilter === "Delivered" || selectedFilter === "All") && (
                    <th scope="col" className="col-1">Actions</th>
                  )} */}
                </tr>
              </thead>
              <tbody>
                {awbList.map((shipment, index) => {
                  const {
                    awbNumber,
                    icon,
                    orgDes,
                    shipmentDate,
                    mainStatus,
                    subStatus,
                    shipmentInfo,
                    subStatusColorClassName,
                  } = getFormattedDataForTable(shipment);
                  return (
                    <tr
                      key={index}
                      className="awb-details-row"
                      onClick={() => awbRowClickHandler(shipment)}
                    >
                      <td>
                        <div className="awb-column">
                          {awbNumber}
                          <span className="ms-2">{icon}</span>
                        </div>
                      </td>
                      <td>{orgDes}</td>
                      <td>{shipmentDate}</td>
                      <td>
                        <div className="status-column">
                          <span className="status-text">{mainStatus}</span>
                          <span
                            className={`status-badge ms-2 ${subStatusColorClassName}`}
                          >
                            {subStatus}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="shipment-info">{shipmentInfo}</div>
                      </td>
                      {/* <td>
                        <button className="view-btn" onClick={() => {
                          viewClickHandler(shipment)
                        }}>Transportation Plan</button>
                      </td> */}
                      {/* {((selectedFilter === "Live" || selectedFilter === "Delivered") ||
                          (selectedFilter === "All" && mainStatus !== "Confirmed" && mainStatus !== "Queued")) && (
                            <td>
                              <button className="view-btn" onClick={() => viewClickHandler(shipment)}>
                                Transportation Plan
                              </button>
                            </td>
                          )} */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {awbList && paginationData.totalPages > 1 && (
              <div className="paginationWrapper">
                <PaginatorComponent
                  currentPage={paginationData.currentPageNumber}
                  rowCount={paginationData.recordsPerPage}
                  totalRecords={paginationData.combinedPages}
                  onPageIndexChange={onPageIndexChange}
                  isHotUpdateNeeded={true}
                />
              </div>
            )}
          </>
        ) : (
          <h4 className="text-center mt-4">No Data</h4>
        )}
      </div>
    </div>
  );
};

export default TrackShipmentListView;
