import moment from 'moment'
import { validateNumericIfPresent } from '../CaptureHawb/CaptureHawbValidations'
import { checkFlightFormat, isAlphaOnly } from '../../utils/Validations'
import Messages from '../../constants/Messages'
import getCurrentUser from '../../utils/getCurrentUser'
export const allowNumricOnly = (evnt) => {
    let isNumber
    evnt = evnt ? evnt : window.event
    let charCode = evnt.which ? evnt.which : evnt.keyCode
    if (charCode) {
        isNumber = charCode > 31 && (charCode < 48 || charCode > 57)
    }
    return isNumber && evnt.preventDefault()
}

export const allowAlphaOnly = (evnt) => {
    let isAlpha
    evnt = evnt ? evnt : window.event
    let charCode = evnt.which ? evnt.which : evnt.charCode
    if (charCode) {
        isAlpha =
            (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)
    }
    return !isAlpha && evnt.preventDefault()
}

export const formatInputTypeNumber = (evnt) => {
    let checkIfNum
    if (evnt.key !== undefined) {
        checkIfNum =
            evnt.key === 'e' ||
            evnt.key === 'E' ||
            evnt.key === '.' ||
            evnt.key === '+' ||
            evnt.key === '-' ||
            evnt.key === ','
    } else if (evnt.keyCode !== undefined) {
        checkIfNum =
            evnt.keyCode === 69 ||
            evnt.keyCode === 190 ||
            evnt.keyCode === 187 ||
            evnt.keyCode === 189 ||
            evnt.keyCode === 190
    }
    return checkIfNum && evnt.preventDefault()
}

export const restrictSpaceSpecial = (e) => {
    let isSpecialCharacter
    let code = e.which ? e.which : e.keyCode
    if (code) {
        isSpecialCharacter =
            (code > 64 && code < 91) ||
            (code > 96 && code < 123) ||
            code === 8 ||
            code === 61 ||
            (code >= 48 && code <= 57)
        return !isSpecialCharacter && e.preventDefault()
    }
}

export const removeError = (event, setError) => {
    const id = event.target.id + ''
    setError((prevState) => ({ ...prevState, [id]: '' }))
}

export const hasError = (key, error) => {
    if (error !== null && error[key] && error[key] !== '') {
        return true
    } else {
        return false
    }
}

export const validateAWB = (value, setError, key) => {
    if (value && value !== null && value.trim() !== '') {
        if (value.length < 8) {
            setError((prevState) => ({
                ...prevState,
                [key]: Messages.INVALID_AWB,
            }))
            return false
        }
        if (value.length == 8) {
            if (value.slice(0, -1) % 7 != value[7]) {
                setError((prevState) => ({
                    ...prevState,
                    [key]: Messages.INVALID_AWB,
                }))
                return false
            } else {
                return true
            }
        }
    } else {
        return true
    }
}

export const validateAwbPrefixRequired = (
    awbNumber,
    awbPrefix,
    setError,
    key
) => {
    if (awbNumber && awbNumber !== null && awbNumber.trim() !== '') {
        if (awbPrefix) {
            if (awbPrefix.length < 3) {
                setError((prevState) => ({
                    ...prevState,
                    [key]: Messages.ENTER_VALID_AWB_PREFIX,
                }))
                return false
            }
            return validateNumericIfPresent(awbPrefix, setError, key)
        } else {
            setError((prevState) => ({
                ...prevState,
                [key]: Messages.ENTER_AWB_PREFIX,
            }))
            return false
        }
    } else {
        return true
    }
}

export const validateFlightNoRegex = (flightNumber, carrierCode, setError) => {
    if (flightNumber && flightNumber !== null && flightNumber.trim() !== '') {
        if (!checkFlightFormat(flightNumber)) {
            setError((prevState) => ({
                ...prevState,
                flightNumber: Messages.INVALID_FLIGHT_NUMBER,
            }))
            return false
        }
        else {
            if (
                checkFlightFormat(flightNumber) &&
                (carrierCode === null || !isAlphaOnly(carrierCode))
            ) {
                setError((prevState) => ({
                    ...prevState,
                    carrierCode: Messages.ENTER_CARRIER_CODE,
                }))
                return false
            }
            else {
                return true
            }
        }
    } else {
        return true
    }
}

export const validateCarrierCodeRequired = (
    flightNumber,
    carrierCode,
    setError,
    key
) => {
    if (flightNumber && flightNumber !== null && flightNumber.trim() !== '') {
        if (carrierCode) {
            if (carrierCode.length < 2) {
                setError((prevState) => ({
                    ...prevState,
                    [key]: Messages.ENTER_VALID_CARRIER_CODE,
                }))
                return false
            }
            return isAlphaOnly(carrierCode)
        } else {
            setError((prevState) => ({
                ...prevState,
                [key]: Messages.ENTER_CARRIER_CODE,
            }))
            return false
        }
    } else {
        return true
    }
}


export const getShipmentsListRequest = (bookingSearchFormData, customBookingStatus, pageNumber, sortData) => {
    const {
        origin,
        destination,
        fromBookingDate,
        toBookingDate,
        shipmentIdentifierDetails,
        advancedSearch,
    } = bookingSearchFormData;
    const { bookingStatus, product } =
        advancedSearch;
    const { masterDocumentNumber, shipmentPrefix } = shipmentIdentifierDetails;
    const userDetails = getCurrentUser();
    const request = {
        origin: origin?.code || null,
        destination: destination?.code || null,
        awbNumber: masterDocumentNumber && shipmentPrefix ? `${shipmentPrefix}-${masterDocumentNumber}` : null,
        shipmentStatusType: customBookingStatus || 'All',
        fromBookingDate: fromBookingDate ?  moment(fromBookingDate).format("DD-MMM-YYYY") : null,
        toBookingDate: toBookingDate ? moment(toBookingDate).format("DD-MMM-YYYY") : null,
        product: product?.name || null,
        systemProductName: product?.systemProductName || null,
        agentCode: userDetails ? userDetails.agentCode : null,
        pageNumber: pageNumber ? pageNumber - 1 : 0,
        pageSize: 10,
        shippingStatus: bookingStatus,
        sortBy: sortData?.sortOrder ? sortData?.sortBy : null,
        sortOrder: sortData?.sortOrder || null
    }
    return request;
};


export const getStatusIconFromStatus = (status) => {
    switch (status) {
        case "Confirmed":
        case "Queued":
            return (
                <div className="status-icon-wrapper">
                    <img src={require("../../assets/images/tick-blue.svg").default} />
                </div>
            );
        case "Accepted":
        case "In-Transit":
        case "In-Transit (Part Departed)":
        case "Arrived":
            return (
                <div className="status-icon-wrapper">
                    <img
                        src={
                            require("../../assets/images/right-arrow-orange.svg").default
                        }
                    />
                </div>
            );
        case "Notified for Delivery":
        case "Delivered":
            return (
                <div className="status-icon-wrapper">
                    <img
                        src={require("../../assets/images/green-thumbs-up.svg").default}
                    />
                </div>
            );
        default:
            return null;
    }
}

const getBgColorBySubStatus = (subStatus) => {
    const statusToCheck = subStatus?.trim()?.toLowerCase();
    if (statusToCheck?.startsWith('potential delay')) {
        return 'potential-delay '
    } else if (statusToCheck?.startsWith('delayed')) {
        return 'delayed'
    } else if (statusToCheck?.startsWith('on track') || statusToCheck?.startsWith('as planned')) {
        return 'on-track'
    } else {
        return ''
    }
}


export const getFormattedDataForTable = (awbDeatils) => {

    const { awbNumber, origin, destination, shipmentStatus, shipmentDate,
        product, commodity, sccCode, subStatus
    } = awbDeatils

    return {
        awbNumber,
        icon: getStatusIconFromStatus(shipmentStatus),
        orgDes: `${origin} - ${destination}`,
        shipmentDate,
        mainStatus: shipmentStatus,
        subStatus: subStatus || '',
        shipmentInfo: `${commodity}\n${product}\n${sccCode}`,
        subStatusColorClassName: subStatus ? getBgColorBySubStatus(subStatus) : ''
    }

}