import { sendEmail } from '../services/notificationService'
import getCurrentUser from './getCurrentUser'
import exceptionEmailProps from '../constants/exceptionEmailProps'
import { getStationByCode } from '../services/searchPanelServices'
import { getPdfAttributes } from '../components/BookingConfirmation/printEmailUtil'
import { addError, pushPageData } from '../utils/analytics'
import dgRestrictedSccs from '../configs/dgRestrictedSccs'
import {
	getFromLocalStorage,
	getFromSessionStorage,
} from '../utils/StorageUtils'

import i18n from '../i18n'
import store from '../redux/store'
import sessionKeys from "../constants/sessionKeys";

export const sendEmailForAssistance = async (
	exceptionType,
	setIsLoading,
	attributes,
	replacements,
	selectedFlight,
	searchFormData,
	saveBookingResponse,
	awbNo,
	hasAttachmentForException,
	emailType,
) => {
	const noRates =
		(selectedFlight &&
			selectedFlight.ratingDetails &&
			selectedFlight.ratingDetails.length &&
			selectedFlight.ratingDetails[0].grandTotal === 0) ||
			(selectedFlight && (selectedFlight.isForceQueued ||
				selectedFlight.overHangShipment ||
				selectedFlight.bigShipment))
			? true
			: false

	setIsLoading && setIsLoading(true)
	const request = await getRequest(
		exceptionType,
		attributes,
		replacements,
		selectedFlight,
		searchFormData,
		saveBookingResponse,
		awbNo,
		hasAttachmentForException,
		noRates,
		emailType,
	)


	if (request) {

		const response = await sendEmail(request)
			.then((response) => {
				setIsLoading && setIsLoading(false)
				if (response.errorDetails) {
					return false
				} else {
					return true
				}
			})
			.catch((error) => {
				setIsLoading && setIsLoading(false)
				addError('EMAIL_EXCEPTION', error)
				return false
			})
		return response
	}
	else {
		return false
	}
}

const getRequest = async (
	exceptionType,
	attributes,
	replacements,
	selectedFlight,
	searchFormData,
	saveBookingResponse,
	awbNo,
	hasAttachmentForException,
	noRates,
	emailType,
) => {
	/*for multilingual*/

	const selectedOriginByUser = attributes?.find(ele => ele?.key === "origin")?.value;
    const isBookingRelatedMail = [
		"OVER_BOOKING",
		"OHG_BOOKING",
		"BIG_BOOKING",
		"SPOT_RATE_BOOKING",
		"QUEUED_BOOKING_NST",
		"ZERO_RATE_BOOKING",
		"OP_WEIGHT_BOOKING",
		"QUEUED_BOOKING",
		"QUEUED_BOOKING_NST"
	  ].includes(exceptionType)

	const t = i18n.t
	let pdfAttributes
	const userDetails = getCurrentUser()
	const emailProps = exceptionEmailProps[exceptionType]
	let stationDtls = null
	if (selectedFlight && selectedFlight.origin) {
		stationDtls = await getStationByCode(selectedFlight.origin)
	}
	if (!stationDtls && exceptionType && exceptionType === 'ALTERNATE_ROUTING_ENQUIRY'
		&& replacements && replacements['%ORG%']) {
		const selectedOrigin = replacements['%ORG%']
		stationDtls = await getStationByCode(selectedOrigin)
	}
	const gsaAgentDetails = JSON.parse(getFromLocalStorage(sessionKeys.GSA_AGENT_DETAILS))
	if (!stationDtls) {
		let defaultAirport = userDetails && userDetails.defaultAirportCode ?  userDetails.defaultAirportCode : userDetails?.stationCode
		if(gsaAgentDetails && gsaAgentDetails?.agentStationCode){
			defaultAirport = gsaAgentDetails.agentStationCode
		}
		stationDtls = await getStationByCode(defaultAirport)
	}
	const language = i18n.language;
	let dgAttachementDetails = [];
	const productCheck = attributes[6]?.value;
	const dgCheckNormal = getFromSessionStorage('ifDg')

	const dgSccCode = (JSON.parse(getFromSessionStorage('dgsccCode')))

	//check for NST sccs
	let isNscIncluded = searchFormData?.sccCodes.some((item) => item.scc === 'NST')


	if (searchFormData?.dgAttachementDetails) {
		searchFormData?.dgAttachementDetails.filter(item => {

			dgAttachementDetails.push(
				{
					attachmentId: item?.attachmentId,
					fileName: item?.fileName,
					id: item?.id
				})
		})
	}

	let unidInfoArray = [];
	if (searchFormData?.unid) {
		searchFormData?.unid?.filter((item, index) => {

			unidInfoArray.push({
				id: item?.id,
				unid: item?.unid,
				shippingName: item?.unidName,
				packingGroup: item?.packingGroup
			})

		})
	}

	if (replacements) {
		replacements['%AGENTNAME%'] =
			userDetails && userDetails?.companyName ? userDetails?.companyName : (userDetails && userDetails?.agentCode ? userDetails?.agentCode : '')
		// replacements['%PRODUCT%'] = attributes && attributes[6]?.value ? attributes[6]?.value : ''
		replacements['%FLIGHTDATE%'] = attributes && attributes[3]?.value ? attributes[3]?.value : ''
		replacements['%ORG%'] = attributes && attributes[0]?.value ? attributes[0]?.value : ''
		replacements['%DES%'] = attributes && attributes[1]?.value ? attributes[1]?.value : ''
	}

	let agentEmail = userDetails ? userDetails.email : null;
	let contactNo = userDetails
		? userDetails.mobileNo
			? userDetails.mobileNo
			: userDetails.telephoneNo
		: null

	const { agentDetails } = store.getState().gsaAgent;
	if (agentDetails) {
		agentEmail = agentDetails?.email || agentEmail;
		contactNo = agentDetails?.contactNo || contactNo;
	}

	let userName = userDetails
		? `${userDetails.firstName ? userDetails.firstName : ''} ${userDetails.lastName ? userDetails.lastName : ''}`
		: null
	if (gsaAgentDetails && gsaAgentDetails.userName && gsaAgentDetails.userName !== '') {
		userName = gsaAgentDetails.userName
	}
	const mailAttributes = {
		body: t(emailProps?.body, {
			agentName: replacements['%AGENTNAME%'],
			description: replacements['%DESCRIPTION%'],
			flightDate: replacements['%FLIGHTDATE%'],
			ORG: replacements['%ORG%'],
			DES: replacements['%DES%'],
			AWB: replacements['%AWB%'],
			REASON: replacements['%REASON%']
		}),
		agentCode: userDetails ? userDetails.agentCode : null,
		userName:userName,
		agentName: userDetails ? userDetails.companyName : null,
		agentEmail,
		contactNo,
		hasAttachments: hasAttachmentForException ? hasAttachmentForException : false,

	}

	if (hasAttachmentForException) {
		pdfAttributes = getPdfAttributes(
			selectedFlight,
			searchFormData,
			null,
			awbNo,
			saveBookingResponse,
			true,
			true,
			null,
			null,
			userDetails,
			noRates,
			'email',
		)
	}

	let animalCount = searchFormData?.crates.map((item) => {
		return item.pets.length
	})

	const getAnimalSum = (animalCount) => {
		let sum = 0;
		for (const item of animalCount) {
			sum += item;
		}
		return sum;
	}


	if (stationDtls && stationDtls?.emailAddress) {

		let emailAddresses = stationDtls.emailAddress.split(',')
		if(userDetails?.defaultAirportCode !== selectedOriginByUser && isBookingRelatedMail){
			const defaultOrgEmail = await getStationByCode(userDetails?.defaultAirportCode)
			emailAddresses = Array.from(new Set([...stationDtls?.emailAddress.split(','),...defaultOrgEmail?.emailAddress.split(',')]));
		}
		// replacements['%PRODUCT%'] = attributes && attributes[6]?.value ? attributes[6]?.value : ''
		replacements['%FLIGHTDATE%'] = attributes && attributes[3]?.value ? attributes[3]?.value : ''
		replacements['%ORG%'] = attributes && attributes[0]?.value ? attributes[0]?.value : ''
		replacements['%DES%'] = attributes && attributes[1]?.value ? attributes[1]?.value : ''
		replacements['%AGENTNAME%'] = userDetails && userDetails.companyName ? userDetails?.companyName : ''

		const request = {
			toEmailAddresses: emailAddresses,
			subject: exceptionType === "dgBookingUpdate" ? `Dangerous Goods Booking Modification Request- ${searchFormData?.shippingItemCode?.description} / ${replacements['%ORG%']}-${replacements['%DES%']} / ${replacements['%FLIGHTDATE%']}`
				: exceptionType === "aviBookingUpdate" ? ` Pet Booking Modify - ${getAnimalSum(animalCount)} ${searchFormData?.shippingItemCode?.description} / ${replacements['%ORG%']}-${replacements['%DES%']} / ${replacements['%FLIGHTDATE%']}`
					: exceptionType === "booking_update" ? t(emailProps.subject, {
						agentName: replacements['%AGENTNAME%'],
						description: replacements['%DESCRIPTION%'],
						product: replacements['%PRODUCT%'],
						AWB: replacements['%AWB%'],
					}) :

						searchFormData?.shippingItemCode?.description === 'LIVE DOGS' || searchFormData?.shippingItemCode?.description === 'LIVE CATS' ? ` Pet Booking Request - ${getAnimalSum(animalCount)} ${searchFormData?.shippingItemCode?.description} / ${replacements['%ORG%']}-${replacements['%DES%']} / ${replacements['%FLIGHTDATE%']}` :

							// ((searchFormData?.shippingItemCode?.product === 'Specialized Commodities') &&
							// 	!(dgRestrictedSccs.includes(searchFormData?.shippingItemCode?.scc))) 
							exceptionType === "DG_ULD" ? `DG ULD booking enquiry  - ${replacements['%ORG%']}-${replacements['%DES%']} / ${replacements['%FLIGHTDATE%']}/  ${replacements['%AGENTNAME%']}` :
								exceptionType === "DG_SP" ? `DG booking enquiry under special provision  - ${attributes && attributes[5]?.value ? attributes[5]?.value : ''} / ${replacements['%ORG%']}-${replacements['%DES%']} / ${replacements['%FLIGHTDATE%']}` :
									((dgSccCode?.includes(searchFormData?.shippingItemCode?.scc)) && exceptionType !== "DG_ULD") || ((dgSccCode?.includes(searchFormData?.shippingItemCode?.scc)) && exceptionType !== "DG_SP") || (dgCheckNormal && dgCheckNormal === 'true' && (exceptionType !== "DG_SP" || exceptionType !== "DG_ULD"))
										? `Dangerous Goods Booking Request - ${searchFormData?.shippingItemCode?.description} / ${replacements['%ORG%']}-${replacements['%DES%']} / ${replacements['%FLIGHTDATE%']}` :
										// : exceptionType === "DG_ULD" ? `DG ULD booking enquiry  - ${replacements['%ORG%']}-${replacements['%DES%']} / ${replacements['%FLIGHTDATE%']}/  ${replacements['%AGENTNAME%']}` :
										// 	exceptionType === "DG_SP" ? `DG booking enquiry under special provision  - ${attributes && attributes[5]?.value ? attributes[5]?.value : ''} / ${replacements['%ORG%']}-${replacements['%DES%']} / ${replacements['%FLIGHTDATE%']}` :
										t(emailProps.subject, {
											agentName: replacements['%AGENTNAME%'],
											description: replacements['%DESCRIPTION%'],
											product: replacements['%PRODUCT%'],
											AWB: replacements['%AWB%'],
											ORG: replacements['%ORG%'],
											DES: replacements['%DES%'],
										}),
			// templateName: language === 'zh' ? "exception_handling" : ((searchFormData?.shippingItemCode?.code === "DOG" || searchFormData?.shippingItemCode?.code === "CAT") ? 'exception_handling_avi' : (searchFormData?.shippingItemCode?.productDescription === 'Dangerous Goods') ? "booking_enquiry_dg" : "exception_handling"),
			templateName: exceptionType === "dgBookingUpdate" ? "booking_modification_dg" : exceptionType === "aviBookingUpdate" ? "booking_modification_avi" :
				(searchFormData?.shippingItemCode?.code === "DOG" ||
					searchFormData?.shippingItemCode?.code === "CAT") ?
					'exception_handling_avi' :
					(exceptionType === "DG_ULD" || exceptionType === "DG_SP") ? "booking_enquiry_dg" :

						((dgSccCode?.includes(searchFormData?.shippingItemCode?.scc)) || (dgCheckNormal && dgCheckNormal === 'true'))
							? 'booking_confirmation_dg' :
							(
								// productCheck === 'Specialized Commodities' || (dgSccCode?.includes(searchFormData?.shippingItemCode?.scc)) || (dgCheckNormal && dgCheckNormal === 'true') ?
								// "booking_enquiry_dg" :
								isNscIncluded ? "booking_confirmation_nst" : "exception_handling"),

			attributes: hasAttachmentForException ? {
				...mailAttributes,
				...pdfAttributes,
			}
				: {
					...mailAttributes,
				},
			hasAttachments: hasAttachmentForException,

			agentCode: userDetails.agentCode,
			userId: userDetails.userId,
			async: false,

			attachmentName: "Booking details",
			specializedCommodity: dgAttachementDetails,
			additionalCommodityDetails: (dgSccCode?.includes(searchFormData?.shippingItemCode?.scc)) || (dgCheckNormal && dgCheckNormal === 'true') ?
				{
					dangerousGoods: true,
					specialProvision: true,
					unidInfo: unidInfoArray
				} : null
			,
			cages: searchFormData?.shippingItemCode?.productDescription === 'Live Animals' ? searchFormData?.crates : null,
			emailType: ((exceptionType === "dgBookingUpdate") || (exceptionType === "aviBookingUpdate") || (exceptionType === "booking_update"))  ? "modifyBooking" : emailType,

		}
		if (isNscIncluded) {
			request.dimensionDetails = searchFormData.shippingType === "ULD" ? searchFormData.mixLooseDetails : searchFormData.looseDetails
		}
		if (attributes && attributes.length) {
			attributes.forEach(function (attr) {
				request.attributes[attr.key] = attr.value
			})
		}
		return request
	} else {
		return null
	}
}

const formatString = (str, replacements) => {
	if (replacements) {
		let formattedStr = str.replace(/%\w+%/g, function (all) {
			return replacements[all] || all
		})
		return formattedStr
	} else {
		return str
	}
}
