import React, { useEffect, useState } from 'react'
import { Route, useHistory, Switch, Redirect } from 'react-router-dom'
import ShipmentTrackingContainer from './ShipmentTrackingContainer'
import TransitView from '../ShipmentTrackingTransitView/TransitView'


const ShipmentTrackingRoot = () => {
	const history = useHistory()

	return (
		<>
			<div className='container-lg cargo-portal-container'>	
					<Switch>
						<Route
							exact
							path='/shipment-tracking'
							render={() => {
								history.push('/shipment-tracking/list')
							}}
						/>
						<Route
							path='/shipment-tracking/list'
							component={ShipmentTrackingContainer}
						/>
						<Route
							path='/shipment-tracking/view'
							component={TransitView}
						/>
					</Switch>
				</div>
		</>
	)
}

export default ShipmentTrackingRoot