import { getWeightUnit } from "../../constants";

export const getShipmentViewDetailsFormatted = (awbViewData) => {
  const transportFlowSummary = awbViewData?.transportFlowSummary;

  const getDescription = (eachStatus) => {
    let descriptionValue = "";
    const {
      receivedPieces,
      actualPieces,
      piecesUnit,
      receivedWeight,
      actualWeight,
      weightUnit,
    } = eachStatus;
    if (receivedPieces && actualPieces && piecesUnit) {
      descriptionValue = `${receivedPieces}/${actualPieces} ${piecesUnit} -`;
    }
    if (receivedWeight && actualWeight && actualWeight) {
      descriptionValue = `${descriptionValue} ${receivedWeight}/${receivedWeight} ${getWeightUnit(weightUnit)}`;
    }

    return descriptionValue;
  };

  const getDateTime = (eachStatus) => {
    let dateTimeValue = '';
    const { carrierCode, flightNumber, dateTime } = eachStatus;
    if (carrierCode && flightNumber) {
      dateTimeValue = `${carrierCode}${flightNumber}`;
    }
    if (dateTime) {
      dateTimeValue = `${dateTimeValue}  ${dateTime}`
    }

    return dateTimeValue?.trim()
  }


  const completedStatuses = ["confirmed", "accepted", "departed", "arrived", 'booked', 'delivered', 'notified for delivery'];
  const partCompletedStatuses = ["departed - delayed", "planned"];

  const getStatusBg = (eachSummary) => {
    const lowerCaseStatus = eachSummary?.status?.toLowerCase();
    if (lowerCaseStatus?.includes("delay")) {
      return "bg-delayed";
    } else if (completedStatuses?.includes(lowerCaseStatus)) {
      return "bg-confirmed";
    } else if (lowerCaseStatus === "planned") {
      return "bg-planned";
    } else {
      return "";
    }
  };

  const getLineCircleStatus = (eachSummary, type) => {
    let result = '';

    let isFullCompletedStatusPresent = false;
    let isHalfCompletedStatusPresent = false;
    eachSummary?.transportFlowSummaryStatusList?.forEach((eachStatus) => {
      const status = eachStatus?.status?.toLowerCase();
      isFullCompletedStatusPresent =
        isFullCompletedStatusPresent || completedStatuses?.includes(status);
      isHalfCompletedStatusPresent =
        isHalfCompletedStatusPresent || partCompletedStatuses?.includes(status);
    });
    if (isFullCompletedStatusPresent) {
      if (!isHalfCompletedStatusPresent) {
        result = type === 'circle' ? "solid" :  "solid-line"
      } else {
        result = type === 'circle' ? "half" : "dotted-line"
      }
    } else if (isHalfCompletedStatusPresent) {
      result = type === 'circle' ? "empty" : "dotted-line"
    }

    return result;
  };


  return transportFlowSummary?.map((eachSummary, index) => ({
    location: eachSummary?.title,
    cards: eachSummary?.transportFlowSummaryStatusList?.map((eachStatus) => ({
      description: getDescription(eachStatus),
      dateTime: getDateTime(eachStatus),
      status: eachStatus?.status,
      statusBg: getStatusBg(eachStatus),
    })),
    colorSchemes: {
      circleStatus: getLineCircleStatus(eachSummary,'circle'),
      lineStatus: getLineCircleStatus(transportFlowSummary?.at(index +1)),
    }
  }));
};
