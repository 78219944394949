import React, { useEffect, useRef, useState } from "react";
import "./GsaUserAgentSelectHeader.scss";
import DownArrow from "../../assets/images/caret-down-outline.svg";
import CloseButton from "../../assets/images/close-outline.svg";
import {
  doGsaAgentSelect,
  isStringEmpty,
  validatAgentCodePattern,
} from "./GsaUserAgentSelectUtil";
import MESSAGES from "../../constants/Messages";

const SwitchAgentPopover = ({
  agentName,
  agentNumber,
  showGsaAgentPopup,
  setShowGsaAgentPopup,
  setIsLoading,
  t,
  gsaAgentData,
  setToGsaAgent,
}) => {
  const [agentCode, setAgentCode] = useState("");
  const [error, setError] = useState("");

  const validateAgentCode = (AgentCode) => {
    if (AgentCode && !validatAgentCodePattern(AgentCode)) {
      setError(t(MESSAGES.VALID_AGENT_CODE, { ns: "errors" }));
    }
  };

  const togglePopup = (isAgentChangeCase) => {
    setAgentCode("");
    setError("");
    setShowGsaAgentPopup((prev) => !prev);
    const currentUrl = window.location.pathname;
    if (isAgentChangeCase) {
      window.location.href = currentUrl;
    }
  };

  const getAgentInitialCircle = () => {
    const initialLetters = agentName
      ?.trim()
      ?.split(" ")
      ?.map((name) => name?.charAt(0))
      .join("");
    return (
      <div className="gsauser-initialscircle">
        {initialLetters?.slice(0, 2)}
      </div>
    );
  };

  const popupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowGsaAgentPopup(false);
      }
    };

    if (showGsaAgentPopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showGsaAgentPopup, setShowGsaAgentPopup]);

  const handleSwitchButtonClick = async () => {
    const isSuccess = await doGsaAgentSelect(
      agentCode,
      gsaAgentData,
      setIsLoading,
      setError,
      setToGsaAgent,
      undefined,
      t,
      true
    );
    if (isSuccess) {
      togglePopup(true);
    }
  };

  return (
    <div className="row">
      <div className="col col-12 pt-0 mb-0">
        <div className="gsauserselect-container pt-3">
          <button className="gsauserselect-button" onClick={()=>togglePopup(false)}>
            {agentName ? (
              <>
                {getAgentInitialCircle()}
                {agentName} - {agentNumber}
              </>
            ) : (
              <>{agentNumber}</>
            )}
            <img src={DownArrow} />
          </button>

          <div
            className={`popup ${showGsaAgentPopup ? "open" : ""}`}
            ref={popupRef}
          >
            <div className="gsauserselect-popup-content">
              <div className="gsauserselect-popup-header">
                {t('COMMON.SWITCH_AGENT')} <img src={CloseButton} onClick={()=>togglePopup(false)} />
              </div>
              <span className="mt-4">
                <input
                  id="AgentCode"
                  className="form-item__field"
                  value={agentCode}
                  maxLength={11}
                  placeholder="Agent code"
                  onChange={(e) => {
                    if (e.target.value.length <= 11) {
                      setAgentCode(e.target.value);
                    }
                  }}
                  aria-invalid={!isStringEmpty(error)}
                  onBlur={(e) => {
                    validateAgentCode(e.target.value);
                  }}
                  onFocus={() => {
                    setError("");
                  }}
                />
              </span>
              <button
                type="button"
                className="primary-button mt-3 mb-1"
                onClick={handleSwitchButtonClick}
              >
                {t('COMMON.SWITCH')}
              </button>
              <a href="/dashboard" className="secondary-button mt-2 d-flex">View Dashboard</a>
              {error &&
                <span className="error-show-area form-item__error mt-2">
                  {error ? error : " "}
                </span>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchAgentPopover;