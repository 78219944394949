import React, { Profiler, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dropdown } from '../../../common/DropDown'
import { UseInput } from '../../../common/UseInput'
import {
	DIMENSION_UNIT_SELECT_ITEMS,
	LANGUAGES,
	MILESTONES,
	USER_ACCOUNT_STATUS,
	USER_TYPES,
	WEIGHT_UNIT_SELECT_ITEMS,
	ACCOUNT_DISABLED_FLAG,
	USER_PRIVLILEGES,
	PAGE_PRIMARY_CATEGORY,
	PAGE_SUB_CATEGORY1,
} from '../../../constants'
import { COUNTRY_CODES } from '../../../constants/countryCodes'
import {
	getUserProfile,
	updateUser,
} from '../../../services/userProfileService'
import LoadingIndicator from '../../LoadingIndicator'
import {
	addAllNotifications,
	getCountryCode,
	getLoggedInUserProfile,
	getPhoneNo,
	getStatus,
	handleMilestone,
	validateMobileCountryCode,
	validateMobileNumber,
	validateName,
	validatePhoneCountryCode,
	validatePhoneNumber,
	getPrivileges,
	getRoles,
	getPortalUserType,
	addAllPrivileges,
} from '../userManagementUtil'
import { sort } from '../../../utils/common'
import CountryCodeDropdown from '../CountryCodeDropdown/index'
import { useTranslation } from 'react-i18next'
import Messages from '../../../constants/Messages'
import MESSAGES from '../../../constants/Messages'
import { MULTILANGUAGE_SUPPORT_ENABLED } from '../../../configs/featureControlConfig'
import { addError, pushPageData } from '../../../utils/analytics'
import pageNames from '../../../constants/pageNames'
const UpdateUserForm = ({
	userCode,
	setUpdateUser,
	setUpdateUserSuccess,
	updateUserSuccess,
	listUsers,
}) => {
	const { t, i18n } = useTranslation(['labels', 'errors'])
	const {
		value: firstName,
		setValue: setFirstName,
		bind: bindFirstName,
		reset: resetFirstName,
	} = UseInput('',{noSpaceValidation: true})
	const {
		value: lastName,
		setValue: setLastName,
		bind: bindLastName,
		reset: resetLastName,
	} = UseInput('',{noSpaceValidation: true})
	const {
		value: phoneNumber,
		setValue: setPhoneNumber,
		bind: bindPhoneNumber,
		reset: resetPhoneNumber,
	} = UseInput('',{noSpaceValidation: true})
	const {
		value: mobileNumber,
		setValue: setMobileNumber,
		bind: bindMobileNumber,
		reset: resetMobileNumber,
	} = UseInput('',{noSpaceValidation: true})

	const {
		value: agentCode,
		setValue: setAgentCode,
		bind: bindAgentCode,
		reset: resetAgentCode,
	} = UseInput('') 

	const [userType, setUserType] = useState('')
	const [userInitialStatus, setUserInitialStatus] = useState('')
	const [userStatus, setUserStatus] = useState('')
	const [language, setLanguage] = useState('')
	const [milestones, setMilestones] = useState({})
	const [notifications, setNotifications] = useState([])

	const [error, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	const [userProfile, setUserProfile] = useState(null)

	const [phContryCode, setPhContryCode] = useState('')
	const [mobContryCode, setMobContryCode] = useState('')
	
	const [privileges, setPrivileges] = useState({
		bookingPrivilege: false,
		capturePrivilege: false,
		dashBoardPrivilege: false,
		informaticsPrivilege: false,
		allotmentPrivilege: false,
	})

	const [preferences, setPrefences] = useState({
		weightUnit: 'K',
		dimensionUnit: 'C',
		language: 'en_US',
	})

	const [privilegeToDisable, setPrivilegeTodisable] = useState(false)
	const [sortedCountryCodes, setSotedCountryCodes] = useState([])
	const [enableAllPrivileges, setEnableAllPrivileges] = useState(false)
	useEffect(() => {
		getUserDetails()
		setUpdateUserSuccess({
			...updateUserSuccess,
			success: false,
			message: '',
		})

		setSotedCountryCodes(sort(COUNTRY_CODES))
	}, [])

	useEffect(() => {
		if (userType) {
			if (userType === 'KU') {
				setPrivilegeTodisable(true)
				setPrivileges({
					...privileges,
					bookingPrivilege: true,
					capturePrivilege: true,
					dashBoardPrivilege: true,
					informaticsPrivilege: true,
					allotmentPrivilege: true,
				})
			} else {
				setPrivilegeTodisable(false)
			}
		}
	}, [userType])

	useEffect(() => {
		if (notifications) {
			if (addAllNotifications(notifications)) {
				setMilestones((prevState) => ({
					...prevState,
					milestone1: true,
				}))
			} else {
				setMilestones((prevState) => ({
					...prevState,
					milestone1: false,
				}))
			}
		}
	}, [notifications])

	useEffect(() => {
		if (privileges) {
			if (addAllPrivileges(privileges)) {
				setEnableAllPrivileges(true)
			} else {
				setEnableAllPrivileges(false)
			}
		}
	}, [privileges])

	const getUserDetails = () => {
		setIsLoading(true)
		getUserProfile({ userCode: userCode !== undefined ? userCode : null })
			.then((response) => {
				setIsLoading(false)
				if (
					response.errorDetails !== undefined &&
					response.errorDetails !== null
				) {
					setUpdateUserSuccess({
						...updateUserSuccess,
						success: false,
						message: `${Messages.USER_CREATION_FAILED} - ${response.errorDetails[0].errorDescription}`,
					})
					addError(
						'ER105',
						i18n.getResource('en', 'errors', MESSAGES.USER_CREATION_FAILED)
					)
					pushPageData(
						pageNames.USER_UPDATE,
						PAGE_PRIMARY_CATEGORY.USER_PROFILE,
						PAGE_SUB_CATEGORY1.USER_MANAGEMENT
					)
				} else {
					setUserProfileDetails(response.userDetails)
					pushPageData(
						pageNames.USER_UPDATE,
						PAGE_PRIMARY_CATEGORY.USER_PROFILE,
						PAGE_SUB_CATEGORY1.USER_MANAGEMENT
					)
				}
			})
			.catch((error) => {
				console.log(error)
				addError(
					'ER105',
					i18n.getResource('en', 'errors', MESSAGES.USER_CREATION_FAILED)
				)
				pushPageData(
					pageNames.USER_UPDATE,
					PAGE_PRIMARY_CATEGORY.USER_PROFILE,
					PAGE_SUB_CATEGORY1.USER_MANAGEMENT
				)
			})
	}

	const setUserProfileDetails = (response) => {
		setUserProfile(response)
		setFirstName(response.firstName)
		setLastName(response.lastName)

		setPhoneNumber(getPhoneNo(response.telephoneNo))
		setPhContryCode(getCountryCode(response.telephoneNo))
		setMobileNumber(getPhoneNo(response.mobileNo))
		setMobContryCode(getCountryCode(response.mobileNo))
		setUserStatus(getStatus(response))
		setUserInitialStatus(getStatus(response))
		setUserType(response.userType)

		setAgentCode(response.agentCode)

		if (response.preference) {
			setPrefences((prevState) => ({
				...prevState,
				weightUnit: response.preference.weightUnit,
				dimensionUnit: response.preference.dimensionUnit,
				language: MULTILANGUAGE_SUPPORT_ENABLED
					? response.preference.language
					: 'en_US',
			}))
			setPrefences((prevState) => ({
				...prevState,
				dimensionUnit: response.preference.dimensionUnit,
			}))
		}
		if (
			response.notificationDetails &&
			response.notificationDetails.notificationAction
		) {
			const notificationMilestones =
				response.notificationDetails.notificationAction
			setNotifications(response.notificationDetails.notificationAction)
			MILESTONES.forEach(function (milestone, index, array) {
				if (notificationMilestones.includes(milestone.label)) {
					setMilestones((prevState) => ({
						...prevState,
						[milestone.id]: true,
					}))
				}
			})
		}
		if (response.roles && response.roles.length) {
			getPrivileges(response.roles, privileges, setPrivileges)
		}
	}

	const validateForm = async () => {
		const fNameValid = await validateName(
			firstName,
			'firstName',
			setError,
			error
		)
		const lNameValid = await validateName(lastName, 'lastName', setError, error)
		const phNoValid = await validatePhoneNumber(
			phoneNumber,
			phContryCode,
			setError,
			error
		)
		const mobNoValid = await validateMobileNumber(
			mobileNumber,
			mobContryCode,
			setError,
			error
		)
		const phnCountryCodeValid = validatePhoneCountryCode(
			phContryCode,
			phoneNumber,
			setError
		)
		const mobCountryCodeValid = validateMobileCountryCode(
			mobContryCode,
			mobileNumber,
			setError
		)
		if (
			fNameValid &&
			lNameValid &&
			phNoValid &&
			phnCountryCodeValid &&
			mobCountryCodeValid &&
			mobNoValid
		) {
			setIsLoading(true)
			updateUser(getRequest())
				.then((response) => {
					setIsLoading(false)
					setUpdateUser(false)
					if (response.errorDetails && response.errorDetails.length > 0) {
						setUpdateUserSuccess({
							...updateUserSuccess,
							success: false,
							message: `${Messages.USER_UPDATE_FAILED} - ${response.errorDetails[0].errorDescription}`,
						})
						addError(
							'ER106',
							i18n.getResource('en', 'errors', MESSAGES.USER_UPDATE_FAILED)
						)
					} else {
						setUpdateUser(false)
						setUpdateUserSuccess({
							...updateUserSuccess,
							success: true,
							message: t(Messages.USER_UPDATE_SUCCESS, {
								userName: `${firstName} ${lastName}`,
								ns: 'errors',
							}),
						})
						if(listUsers){
							listUsers();
						}

						getUserDetails()
					}
					window.scrollTo(0, 0)
				})
				.catch((error) => {
					setUpdateUser(false)
					setIsLoading(false)
					setUpdateUserSuccess({
						...updateUserSuccess,
						success: false,
						message: Messages.USER_UPDATE_FAILED,
					})
					addError(
						'ER106',
						i18n.getResource('en', 'errors', MESSAGES.USER_UPDATE_FAILED)
					)
					getUserDetails()
				})
		} else {
			console.log('has form error', error)
		}
	}

	const handleSubmit = (evt) => {
		evt.preventDefault()
		validateForm()
	}

	const onChangeUserType = (event) => {
		setUserType(event.target.value)
	}

	const onChangeStatus = (event) => {
		setUserStatus(event.target.value)
	}

	const onChangeLanguage = (event) => {
		setPrefences({ ...preferences, language: event.target.value })
	}

	const onChangeWeightUnit = (event) => {
		setPrefences({ ...preferences, weightUnit: event.target.value })
	}

	const onChangeDimensionUnit = (event) => {
		setPrefences({ ...preferences, dimensionUnit: event.target.value })
	}

	const handleChangeMilestone = (event) => {
		if (event.target.id === 'milestone1') {
			MILESTONES.map((milestone) => {
				setMilestones((prevState) => ({
					...prevState,
					[milestone.id]: true,
				}))
			})
		}
		handleMilestone(event, notifications, setNotifications, setMilestones)
	}

	const handleCancelAction = () => {
		setUpdateUser(false)
		window.scrollTo(0, 0)
	}

	/*Privilege Management*/
	const handleChangePrivileges = (evnt) => {
		const key = evnt.target.id
		const isChecked = evnt.target.checked
		isChecked ? setEnableAllPrivileges(true) : setEnableAllPrivileges(false)
		if (key === 'allPrivileges') {
			setPrivileges((prevState) => ({
				...prevState,
				bookingPrivilege: isChecked,
				capturePrivilege: isChecked,
				dashBoardPrivilege: isChecked,
				informaticsPrivilege: isChecked,
				allotmentPrivilege: isChecked,
			}))
		}
		if (key === 'dashBoardPrivilege' && !isChecked) {
			setPrivileges((prevState) => ({
				...prevState,
				dashBoardPrivilege: isChecked,
				informaticsPrivilege: isChecked,
				allotmentPrivilege: isChecked,
			}))
		} else {
			setPrivileges((prevState) => ({
				...prevState,
				[key]: isChecked,
			}))
		}
	}

	const onChangePhCountryCode = (event) => {
		setPhContryCode(event.target.value)
	}

	const onChangeMobCountryCode = (event) => {
		setMobContryCode(event.target.value)
	}

	const removeError = (event) => {
		const id = event.target.id + ''
		setError((prevState) => ({ ...prevState, [id]: '' }))
	}

	const hasError = (key) => {
		if (error !== null && error[key] && error[key] !== '') {
			return true
		} else {
			return false
		}
	}

	const getRequest = () => {
		const loggedInUserProfile = getLoggedInUserProfile()
		const roles = getRoles(privileges)
		const requestBody = {
			requestId: Math.floor(Math.random() * 89999 + 10000),
			userDetails: {
				userId: userProfile.userId,
				firstName,
				middleName: userProfile.middleName,
				lastName,
				email: userProfile.email,
				telephoneNo:
					phContryCode !== '' && phoneNumber !== ''
						? `${phContryCode} ${phoneNumber}`
						: null,
				mobileNo:
					mobContryCode !== '' && mobileNumber !== ''
						? `${mobContryCode} ${mobileNumber}`
						: null,
				//agentCode: loggedInUserProfile.agentCode,

				agentCode: agentCode,

				customerCode: userProfile?.customerCode ? userProfile.customerCode : null,
				companyName: userProfile.companyName,
				companyCode: userProfile.companyCode,
				statusChanged: userInitialStatus === userStatus ? false : true,
				accountDisabledFlag: userStatus === 'I' ? 'Y' : 'N',
				address: userProfile.address,
				city: userProfile.city,
				state: userProfile.state,
				country: userProfile.country,
				postalCode: userProfile.postalCode,
				stationCode: userProfile.stationCode,
				defaultAirportCode: userProfile.defaultAirportCode,
				weightUnit: userProfile.weightUnit,
				dimensionUnit: userProfile.dimensionUnit,
				userType: userType,
				preference: preferences,
				roles: roles.length ? roles : null,
				// roleGroup:
				// 	userType != null
				// 		? userType === 'KU'
				// 			? 'PORADMUSR'
				// 			: 'PORBKGUSR'
				// 		: null,
				notificationDetails: {
					notificationAction: notifications,
					notificationEmail: [userProfile.email],
					notificationPhoneNo:
						mobContryCode && mobileNumber
							? [`${mobContryCode} ${mobileNumber}`]
							: null,
				},
			},
		}
		return requestBody
	}

	const handleAlertClose = () => {
		setUpdateUserSuccess({
			...updateUserSuccess,
			success: false,
			message: '',
		})
	}

	const triggerPhoneCodeValidation = () => {
		validatePhoneCountryCode(phContryCode, phoneNumber, setError)
	}

	const triggerMobPhoneCodeValidation = () => {
		validateMobileCountryCode(mobContryCode, mobileNumber, setError)
	}

	return (
		<div className='update-user-panel'>
			{isLoading ? <LoadingIndicator /> : <></>}
			{userProfile && (
				<div>
					<div className='form-row mt-5 mb-3'>
						<div className='col-12 col-md-8'>
							<h3 className='page-sub-head mt-0'>{t('COMMON.USER_INFO')}</h3>
						</div>
						{userProfile.lastLoginTime && (
							<div className='col-12 col-md-4'>
								<span className='userLastLogin'>
									{t('COMMON.LAST_LOGIN')} : {userProfile.lastLoginTime}{' '}
								</span>
							</div>
						)}
					</div>
					<div className='user-info'>
						<div className='form-row mt-3'>
							<div
								className='col-12 col-lg-4 form-item form-item--text form-item--disabled'
								data-module='molecules/form-item/FormItem'
							>
								<label className='form-item__label'>
									{t('COMMON.USER_ID')}
								</label>
								<input
									type='text'
									className='form-item__field'
									value={userProfile.userId}
									maxLength='25'
									readOnly
								/>
							</div>
							<div
								className='col-12 col-lg-4 form-item form-item--text form-item--disabled'
								data-module='molecules/form-item/FormItem'
							>
								<label className='form-item__label'>{t('COMMON.EMAIL')}</label>
								<input
									type='text'
									className='form-item__field'
									value={userProfile.email}
									maxLength='100'
									readOnly
								/>
							</div>
						</div>
						<div className='form-row custom-mt-5'>
							<div
								className='col-12 col-lg-4 form-item form-item--text'
								data-module='molecules/form-item/FormItem'
							>
								<label className='form-item__label'>
									{t('COMMON.FIRST_NAME')}
								</label>
								<input
									id='firstName'
									type='text'
									{...bindFirstName}
									className='form-item__field'
									maxLength='20'
									aria-invalid={hasError('firstName')}
									onFocus={removeError}
									onBlur={(e) => {
										validateName(firstName, 'firstName', setError, error)
									}}
								/>
								<span className='form-item__error'>
									{error && error['firstName']
										? t(error['firstName'], { ns: 'errors' })
										: ''}
								</span>
							</div>
							<div
								className='col-12 col-lg-4 form-item form-item--text'
								data-module='molecules/form-item/FormItem'
							>
								<input
									id='lastName'
									type='text'
									{...bindLastName}
									className='form-item__field'
									maxLength='20'
									aria-invalid={hasError('lastName')}
									onFocus={removeError}
									onBlur={(e) => {
										validateName(lastName, 'lastName', setError, error)
									}}
								/>
								<label className='form-item__label'>
									{t('COMMON.LAST_NAME')}
								</label>
								<span className='form-item__error'>
									{error && error['lastName']
										? t(error['lastName'], { ns: 'errors' })
										: ''}
								</span>
							</div>
						</div>
						<div className='form-row custom-mt-5'>
							<div className='col-12 col-lg-4 form-item'>
								<Dropdown
									className='cust-dropdown'
									value={getPortalUserType(userType)}
									options={USER_TYPES}
									label={t('COMMON.ACCOUNT_TYPE')}
									onChange={onChangeUserType}
								></Dropdown>
							</div>
							<div className='col-12 col-lg-4 form-item'>
								<Dropdown
									className='cust-dropdown'
									value={userStatus}
									options={USER_ACCOUNT_STATUS}
									label={t('COMMON.STATUS')}
									onChange={onChangeStatus}
								></Dropdown>
							</div>
						</div>

						<div className='form-row custom-mt-5'>
							<div
								className='col-12 col-lg-4 form-item form-item--text'
								data-module='molecules/form-item/FormItem'
							>
								<label className='form-item__label'>
									{t('COMMON.AGT_CODE')}
								</label>
								<input 
									disabled="true"
									id='agentcode'
									type='text'
									{...bindAgentCode}
									className='form-item__field'
									//maxLength='20'
								/>
							</div>
							</div>

						<div className='form-row custom-mt-5'>
							<div className='col-12 col-md-12 user-privileges add-account'>
								<label className='form-item__label'>{t('UM.PRIVILEGES')}</label>
								<div className='form-row mt-3'>
									<div className='previlege_label col-12 col-lg-auto'>
										<div
											className='form-item form-item--checkbox'
											data-module='molecules/form-item/FormItem'
										>
											<input
												id='allPrivileges'
												checked={enableAllPrivileges}
												value={enableAllPrivileges}
												disabled={privilegeToDisable}
												type='checkbox'
												name='allPrivileges'
												className='form-item__field'
												onChange={handleChangePrivileges}
											/>
											<label
												htmlFor='allPrivileges'
												className='form-item__label'
											>
												{t('COMMON.ALL')}
											</label>
										</div>
									</div>
									<div className='previlege_label col-12 col-lg-auto'>
										<div
											className='form-item form-item--checkbox'
											data-module='molecules/form-item/FormItem'
										>
											<input
												id='bookingPrivilege'
												checked={privileges.bookingPrivilege}
												value={privileges.bookingPrivilege}
												type='checkbox'
												disabled={privilegeToDisable}
												name='bookingPrivilege'
												className='form-item__field'
												onChange={handleChangePrivileges}
											/>
											<label
												htmlFor='bookingPrivilege'
												className='form-item__label'
											>
												{t('COMMON.BKG')}
											</label>
										</div>
									</div>

									<div className='previlege_label col-12 col-lg-auto'>
										<div
											className='form-item form-item--checkbox'
											data-module='molecules/form-item/FormItem'
										>
											<input
												id='capturePrivilege'
												checked={privileges.capturePrivilege}
												value={privileges.capturePrivilege}
												disabled={privilegeToDisable}
												type='checkbox'
												name='capturePrivilege'
												className='form-item__field'
												onChange={handleChangePrivileges}
											/>
											<label
												htmlFor='capturePrivilege'
												className='form-item__label'
											>
												{t('COMMON.DATA_CAPTURE')}
											</label>
										</div>
									</div>

									<div className='previlege_label col-12 col-lg-auto'>
										<div
											className='form-item form-item--checkbox'
											data-module='molecules/form-item/FormItem'
										>
											<input
												id='dashBoardPrivilege'
												checked={privileges.dashBoardPrivilege}
												value={privileges.dashBoardPrivilege}
												disabled={privilegeToDisable}
												type='checkbox'
												name='dashBoardPrivilege'
												className='form-item__field'
												onChange={handleChangePrivileges}
											/>
											<label
												htmlFor='dashBoardPrivilege'
												className='form-item__label'
											>
												{t('UM.DASHBOARD_DEFAULT_PRVLG')}
											</label>
										</div>
									</div>

									<div className='previlege_label col-12 col-lg-auto'>
										<div
											className='form-item form-item--checkbox'
											data-module='molecules/form-item/FormItem'
										>
											<input
												id='informaticsPrivilege'
												checked={privileges.informaticsPrivilege}
												value={privileges.informaticsPrivilege}
												disabled={
													privilegeToDisable || !privileges.dashBoardPrivilege
												}
												type='checkbox'
												name='informaticsPrivilege'
												className='form-item__field'
												onChange={handleChangePrivileges}
											/>
											<label
												htmlFor='informaticsPrivilege'
												className='form-item__label'
											>
												{t('UM.IFOGRPCS_PRVLG')}
											</label>
										</div>
									</div>

									<div className='previlege_label col-12 col-lg-auto'>
										<div
											className='form-item form-item--checkbox'
											data-module='molecules/form-item/FormItem'
										>
											<input
												id='allotmentPrivilege'
												checked={privileges.allotmentPrivilege}
												value={privileges.allotmentPrivilege}
												disabled={
													privilegeToDisable || !privileges.dashBoardPrivilege
												}
												type='checkbox'
												name='allotmentPrivilege'
												className='form-item__field'
												onChange={handleChangePrivileges}
											/>
											<label
												htmlFor='allotmentPrivilege'
												className='form-item__label'
											>
												{t('UM.ALLOTMNT_PRVLG')}
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='form-row custom-mt-5'>
							<div
								className='col-12 col-lg-4 form-item form-item--text'
								data-module='molecules/form-item/FormItem'
							>
								<label className='form-item__label'>
									{t('CREATEUSER.PHONE_NO')}
								</label>
								<div>
									<div className='form-row'>
										<div
											className={`col-4 col-lg-4 form-item pl-1
											 ${error && error['phContryCode'] ? 'red-border' : ''}`}
										>
											{/* <Dropdown
												className='cust-dropdown'
												id='phContryCode'
												value={phContryCode}
												options={COUNTRY_CODES}
												onFocus={removeError}
												onChange={onChangePhCountryCode}
												defaultText='-'
												ariaInvalid={hasError('phContryCode')}
												onBlur={(e) => {
													validatePhoneCountryCode(
														phContryCode,
														phoneNumber,
														setError
													)
												}}
											/> */}
											<CountryCodeDropdown
												id='phContryCode'
												name='phContryCode'
												countryCodes={sortedCountryCodes}
												userCountryCode={phContryCode}
												setCountryCode={setPhContryCode}
												triggerCodeValidation={triggerPhoneCodeValidation}
												setError={setError}
												removeError={removeError}
											/>
											<span className='form-item__error'>
												{error && error['phContryCode']
													? t(error['phContryCode'], { ns: 'errors' })
													: ''}
											</span>
										</div>
										<div className='col-8 col-lg-8'>
											<input
												type='text'
												id='phoneNumber'
												{...bindPhoneNumber}
												className='form-item__field'
												maxLength='15'
												aria-invalid={hasError('phoneNumber')}
												onFocus={removeError}
												onBlur={(e) => {
													validatePhoneNumber(
														phoneNumber,
														phContryCode,
														setError,
														error
													)
												}}
											/>
											<span className='form-item__error'>
												{error && error['phoneNumber']
													? t(error['phoneNumber'], { ns: 'errors' })
													: ''}
											</span>
										</div>
									</div>
								</div>
							</div>
							<div
								className='col-12 col-lg-4 form-item form-item--text'
								data-module='molecules/form-item/FormItem'
							>
								<label className='form-item__label'>
									{t('CREATEUSER.MOBILE_NO')}
								</label>
								<div>
									<div className='form-row'>
										<div
											className={`col-4 col-lg-4 form-item pl-1
											 ${error && error['mobContryCode'] ? 'red-border' : ''}`}
										>
											{/* <Dropdown
												className='cust-dropdown'
												id='mobContryCode'
												onFocus={removeError}
												value={mobContryCode}
												options={COUNTRY_CODES}
												onChange={onChangeMobCountryCode}
												defaultText='-'
												ariaInvalid={hasError('mobContryCode')}
												onBlur={(e) => {
													validateMobileCountryCode(
														mobContryCode,
														mobileNumber,
														setError
													)
												}}
											/> */}
											<CountryCodeDropdown
												id='mobContryCode'
												name='mobContryCode'
												autoCompleteType='MOB_COUNTRY_CODE'
												countryCodes={sortedCountryCodes}
												userCountryCode={mobContryCode}
												setCountryCode={setMobContryCode}
												triggerCodeValidation={triggerMobPhoneCodeValidation}
												setError={setError}
												removeError={removeError}
											/>
											<span className='form-item__error'>
												{error && error['mobContryCode']
													? t(error['mobContryCode'], { ns: 'errors' })
													: ''}
											</span>
										</div>

										<div className='col-8 col-lg-8'>
											<input
												id='mobileNumber'
												type='text'
												{...bindMobileNumber}
												className='form-item__field'
												maxLength='15'
												aria-invalid={hasError('mobileNumber')}
												onFocus={removeError}
												onBlur={(e) => {
													validateMobileNumber(
														mobileNumber,
														mobContryCode,
														setError,
														error
													)
												}}
											/>
											<span className='form-item__error'>
												{error && error['mobileNumber']
													? t(error['mobileNumber'], { ns: 'errors' })
													: ''}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div>
						<div className='form-row'>
							<h3 className='col-12 col-lg-12 custom-mt-5 page-sub-head'>
								{t('COMMON.PREF')}
							</h3>
						</div>
						<div className='form-row mt-1'>
							<div className='col-12 col-lg-4 form-item  pl-2'>
								<Dropdown
									className='cust-dropdown'
									value={preferences.language}
									options={LANGUAGES}
									label={t('COMMON.LANG')}
									onChange={onChangeLanguage}
									disabled={!MULTILANGUAGE_SUPPORT_ENABLED}
								></Dropdown>
							</div>
						</div>
						<div className='form-row custom-mt-5'>
							<div className='col-6 col-lg-2 form-item pl-2'>
								<Dropdown
									className='cust-dropdown'
									value={preferences.weightUnit}
									options={WEIGHT_UNIT_SELECT_ITEMS}
									label={t('COMMON.WT')}
									onChange={onChangeWeightUnit}
									isUpdate={true}
								></Dropdown>
							</div>
							<div className='col-6 col-lg-2 form-item'>
								<Dropdown
									className='cust-dropdown'
									value={preferences.dimensionUnit}
									options={DIMENSION_UNIT_SELECT_ITEMS}
									label={t('COMMON.DIM')}
									onChange={onChangeDimensionUnit}
									isUpdate={true}
								></Dropdown>
							</div>
						</div>
					</div>

					<div>
						<div className='form-row'>
							<div className='col-12 col-lg-12'>
								<h3 className='custom-mt-5 page-sub-head'>
									{t('COMMON.NOTIFICATION')}
								</h3>
							</div>
						</div>
						<div className='form-row mt-3 notifications-info'>
							<div className='col-12 col-lg-12'>
								<div className='form-row notify_list'>
									<div className='col-12 col-lg-auto'>
										<div
											className='form-item form-item--checkbox'
											data-module='molecules/form-item/FormItem'
										>
											<input
												id='milestone1'
												checked={milestones.milestone1}
												value='All'
												type='checkbox'
												name='milestone1'
												className='form-item__field '
												onChange={handleChangeMilestone}
											/>
											<label htmlFor='milestone1' className='form-item__label'>
												{t('COMMON.ALL')}
											</label>
										</div>
									</div>
									<div className='col-12 col-lg-auto'>
										<div
											className='form-item form-item--checkbox'
											data-module='molecules/form-item/FormItem'
										>
											<input
												id='milestone2'
												checked={milestones.milestone2}
												value='Accepted'
												type='checkbox'
												name='milestone2'
												className='form-item__field'
												onChange={handleChangeMilestone}
											/>
											<label htmlFor='milestone2' className='form-item__label'>
												{t('COMMON.ACCEPTED')}
											</label>
										</div>
									</div>
									<div className='col-12 col-lg-auto'>
										<div
											className='form-item form-item--checkbox'
											data-module='molecules/form-item/FormItem'
										>
											<input
												id='milestone3'
												checked={milestones.milestone3}
												value='Departed'
												type='checkbox'
												name='milestone3'
												className='form-item__field'
												onChange={handleChangeMilestone}
											/>
											<label htmlFor='milestone3' className='form-item__label'>
												{t('COMMON.DEPARTED')}
											</label>
										</div>
									</div>
									<div className='col-12 col-lg-auto'>
										<div
											className='form-item form-item--checkbox'
											data-module='molecules/form-item/FormItem'
										>
											<input
												id='milestone4'
												checked={milestones.milestone4}
												value='Arrived'
												type='checkbox'
												name='milestone4'
												className='form-item__field'
												onChange={handleChangeMilestone}
											/>
											<label htmlFor='milestone4' className='form-item__label'>
												{t('COMMON.ARRIVED')}
											</label>
										</div>
									</div>
									<div className='col-12 col-lg-auto'>
										<div
											className='form-item form-item--checkbox'
											data-module='molecules/form-item/FormItem'
										>
											<input
												id='milestone5'
												checked={milestones.milestone5}
												value='Notified'
												type='checkbox'
												name='milestone5'
												className='form-item__field'
												onChange={handleChangeMilestone}
											/>
											<label htmlFor='milestone5' className='form-item__label'>
												{t('COMMON.NOTIFIED')}
											</label>
										</div>
									</div>
									<div className='col-12 col-lg-auto'>
										<div
											className='form-item form-item--checkbox'
											data-module='molecules/form-item/FormItem'
										>
											<input
												id='milestone6'
												checked={milestones.milestone6}
												value='Delivered'
												type='checkbox'
												name='milestone6'
												className='form-item__field'
												onChange={handleChangeMilestone}
											/>
											<label htmlFor='milestone6' className='form-item__label'>
												{t('COMMON.DELIVERED')}
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='custom-mobile-row custom-mt-5'>
						<div className='cta_wrap flex-column-reverse flex-lg-column custom-ml-1 custom-mt-3 col-12'>
							<button className='primary-button' onClick={handleSubmit}>
								{t('COMMON.SAVE')}
							</button>
							<button
								className='button_cancel secondary-button custom-ml-4'
								onClick={handleCancelAction}
							>
								{t('COMMON.CANCEL')}
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
const mapStateToProps = ({ loadingDetails }) => ({
	loadingDetails: loadingDetails,
})
const mapDispatchToProps = (dispatch, message) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserForm)
