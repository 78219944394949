import envConfigs from "../configs/envConfigs"
import { portalAuthHttpClient } from "../utils/HttpRequestUtils"

export const getShipmentsList = async (data) => {
    const url = `${envConfigs.csnUrl}${envConfigs.getShipmentsList}`
    //const url = `https://4fe9a2d7-1345-4cbf-95d2-444a00b7abeb.mock.pstmn.io/${envConfigs.getShipmentsList}`
    const response = await portalAuthHttpClient.post(url, data)
    return response
}


export const getAwbShipmemtDetails = async (awbNo) => {
    const url = `${envConfigs.csnUrl}${envConfigs.getAwbShipmemtDetails}${awbNo}`
    //const url = `https://ea0bf2d9-fe07-4113-ac8c-a500d7b3fb14.mock.pstmn.io/${envConfigs.getAwbShipmemtDetails}${awbNo}`
    const response = await portalAuthHttpClient.get(url)
    return response
}



