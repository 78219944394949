import React from 'react'
import { connect } from 'react-redux'
import BookingMenu from '../BookingMenu'
import TrackShipmentListView from './TrackShipmentListView'

const ShipmentTrackingContainer = ({

}) => {
	return (
		<div>
			 <BookingMenu activeItem={2}/>
             <TrackShipmentListView/>		
		</div>
	)
}

export default ShipmentTrackingContainer;