import { Paginator } from 'primereact/paginator'
import React, { useEffect, useRef, useState } from 'react'
import './pagination-component.scss'

const PaginatorComponent = ({
	currentPage,
	pageIndex,
	rowCount,
	totalRecords,
	onPageIndexChange,
	pageReset,
	isHotUpdateNeeded
}) => {
	const [first, setFirst] = useState(currentPage)
	const [page, setPage] = useState(pageIndex)

	useEffect(() => {
		if(pageReset){
			setFirst(1)
		}
	},[pageReset])

	const onPageChange = (e) => {
		setFirst(e.first)
		setPage(e.page + 1)
		onPageIndexChange(e.page + 1, e.first)
	}

	useEffect(() => { if (isHotUpdateNeeded) { setFirst(currentPage === 0 ? currentPage : (currentPage - 1) * rowCount); } }, [currentPage])

	return (
		<Paginator
			first={first}
			rows={rowCount}
			totalRecords={totalRecords}
			onPageChange={onPageChange}
		/>
	)
}
export default PaginatorComponent
