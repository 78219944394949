import React, { useState, useEffect } from 'react'
import { TabMenu } from 'primereact/tabmenu'
import { useHistory } from 'react-router-dom'
import './index.scss'
import { useTranslation } from 'react-i18next';
import { MASS_BOOKING_ENABLED } from '../../configs/featureControlConfig';
import { getUserConfig } from '../../services/permanentService';
import {clearItemFromLocalStorage, getFromLocalStorage, setToLocalStorage } from '../../utils/StorageUtils'
import sessionKeys from '../../constants/sessionKeys'

const BookingMenu = ({ activeItem }) => {
	const history = useHistory()
	const { t } = useTranslation(['labels']);
	const [items, setItems] = useState([])

	const [active, setActive] = useState(items[0])

	//const [flag, setFlag] = useState(false);

	const getPermanentBkgFlag = async () => {
		let permanentBkgFlag = getFromLocalStorage(sessionKeys.PERMANENT_BKG_FLG)
		if (permanentBkgFlag) {
			return permanentBkgFlag
		} else {
			permanentBkgFlag = await getUserConfig().then(value => {
				if (value?.permanentBookingFlag && value?.permanentBookingFlag) {
					return value?.permanentBookingFlag
				} else {
					return 'N'
				}
			})
			setToLocalStorage(sessionKeys.PERMANENT_BKG_FLG, permanentBkgFlag)
			return permanentBkgFlag
		}
	}



	useEffect(() => {
		; (async () => {
			let flag = false;
			let initItems = [];
			const permanentBkgFlag = await getPermanentBkgFlag()
			//setFlag(value.permanentBookingFlag == 'Y' ? true: false  )
			permanentBkgFlag === 'Y' ? flag = true : flag = false
			initItems = [
				{
					label: t('COMMON.NEW_BKG'),
					command: (event) => {
						history.push('/booking')
					},
					className: 'tab-new-booking',
				},
				{
					label: t('BOOKING.MANAGE_BOOKINGS'),
					command: (event) => {
						history.push('/manage-booking/list')
					},
					className: 'tab-my-booking',
				},
				{
					label: t('BOOKING.SHIPMENT_TRACKING'),
					command: (event) => {
						clearItemFromLocalStorage(sessionKeys.SHIPMENT_TRACKING_DETAILS)
						history.push('/shipment-tracking/list')
					},
					className: 'tab-shipment-tracking',
				},
				{
					label: t('ROUTING_ENQUIRY.ROUTING_ENQUIRY'),
					command: (event) => {
						history.push('/flight-enquiry')
					},
					className: 'tab-flight-enquiry',
				},
				{
					label: t('TEMPLATES.TEMPLATES'),
					command: (event) => {
						history.push('/manage-template')
					},
					className: 'tab-templates',
				},
				MASS_BOOKING_ENABLED && flag ? {
					label: t('COMMON.MULTIPLE_BOOKING'),
					command: (event) => {
						history.push('/permanent-booking')
					},
					className: 'tab-permanent-booking',
				} : null,
			]
			if (activeItem !== null) {
				setActive(initItems[activeItem])
				let menuItems = [...initItems]
				menuItems[
					activeItem
				].className = `${menuItems[activeItem].className} p-highlight`
				setItems(menuItems)
			}
		})();
	}, [])


	useEffect(() => {
		if (activeItem !== null && active !== undefined) {
			setActive(items[activeItem])
			let menuItems = [...items]
			menuItems[
				activeItem
			].className = `${menuItems[activeItem].className} p-highlight`
			setItems(menuItems)
		}
	}, [activeItem])

	const onTabChange = (e) => {}

	return (
		<div className='cargo-portal-container'>
			<div className='booking-heading'>
				<div>
					<h3 className='page-heading mt-0'>{t('COMMON.BKG_HEAD')}</h3>
				</div>
			</div>
			<div className='portal-tab-menu'>
				<TabMenu
					className='tab-booking'
					model={items.filter(item => item)}
					onTabChange={(e) => onTabChange(e)}
					activeItem={active}
				/>
			</div>
		</div>
	)
}

export default BookingMenu
