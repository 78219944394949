import React from 'react'
import { connect } from 'react-redux'
import FlightSearch from './FlightSearch'
import { loadFlightEnquiry } from '../../../redux/actions/flightEnquiryActions'
import { showPopup } from '../../../redux/actions/popupActions'
import BookingMenu from '../../BookingMenu'

const FlightSearchContainer = ({
	destinationAirport,
	getFlightEnquiryList,
	flightEnquiryError,
	flightEnquiryResponse,
	showErrorPopup,
	gsaAgentDetails
}) => {
	return (
		<div>
			<BookingMenu activeItem={3} />
			<FlightSearch
				destinationAirport={destinationAirport}
				getFlightEnquiryList={getFlightEnquiryList}
				showErrorPopup={showErrorPopup}
				flightEnquiryResponse={flightEnquiryResponse}
				flightEnquiryError={flightEnquiryError}
				gsaAgentDetails={gsaAgentDetails}
			/>
		</div>
	)
}
const mapStateToProps = ({ flightEnquiry,gsaAgent }) => ({
	flightEnquiryResponse: flightEnquiry.response,
	flightEnquiryError: flightEnquiry.error,
	gsaAgentDetails : gsaAgent
})
const mapDispatchToProps = (dispatch) => ({
	getFlightEnquiryList: (formData) => {
		dispatch(loadFlightEnquiry(formData))
	},
	showErrorPopup: (
		header,
		message,
		primaryBtnLbl,
		acceptFn,
		secondaryBtnLbl,
		rejectFn
	) => {
		dispatch(
			showPopup(
				header,
				message,
				primaryBtnLbl,
				acceptFn,
				secondaryBtnLbl,
				rejectFn
			)
		)
	},
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FlightSearchContainer)
